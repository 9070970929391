import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#000000] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
              About
            </p>
          </div>
          <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>Hello there. nice to meet you.Feel free to ask me anything and please look around.</p>
            </div>
            <div>
              <p>Enthusiastic and challenge-driven Full Stack Developer. I Provide scalable, and secure software solutions using modern design patterns and technologies. Eager to build the next generation of sophisticated web applications.

              Skilled in Web Development using HTML, CSS, React, PHP, Javascript, Typescript, Bootstrap, Tailwind CSS.
              
              Skilled in SQL and NoSQL Databases using MySQL, MongoDB.
              
              Skilled in Frameworks Laravel (PHP).
              Skilled in Google(Firebase).
              
              Exposure in DevOps using Docker, Terraform, Kubernetes.
              
              Exposure to Kafka and Spark.</p>  
            </div>
          </div>
      </div>
    </div>
  );
};

export default About;