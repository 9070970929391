import React from 'react';

import HTML from '../Resource/html.png';
import CSS from '../Resource/css.png';
import JavaScript from '../Resource/javascript.png';
import ReactImg from '../Resource/react.png';
import Node from '../Resource/node.png';
import FireBase from '../Resource/firebase.png';
import AWS from '../Resource/aws.png';
import GitHub from '../Resource/GitHub-Mark.png';
import Tailwind from '../Resource/tailwind.png';
import Mongo from '../Resource/mongo.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen  text-gray-300 bg-[#000000]' >
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-blue-600 '>Skills</p>
              <p className='py-4'>These are among the technologies I've worked with</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8 bg-[#000000]'>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-full mx-auto' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                  <p className='my-4'>CSS</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                  <p className='my-4'>REACT</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={GitHub} alt="HTML icon" />
                  <p className='my-4'>GITHUB</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Node} alt="HTML icon" />
                  <p className='my-4'>NODE JS</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Mongo} alt="HTML icon" />
                  <p className='my-4'>MONGO DB</p>
              </div>
              <div className='shadow-md shadow-[#b0ba43] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={FireBase} alt="HTML icon" />
                  <p className='my-4'>Firebase</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Skills;