import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#060606]'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#c3d80d]'>Hi, I am</p>
        <h1 className='text-3xl sm:text-7xl text-[#c3d80d]'>
        Filimon
        </h1>
        
        <h2 className='text-3xl sm:text-7xl font-bold text-[#8892b0]'>
          I'm a Full-Stack Developer.
        </h2>
        <div className='text-[#8892b0] text-2xl w-full text-justify max-w-[700px]'>
        <p >
        I am A passionate, Dedicated, Problem Solver  with three years of experience as a full-Stack developer. I can assimilate new ideas efficiently. I love being part of effective team that can produce measurable success. 

        </p>
        </div>
        <div>
        <Link to='work' smooth={true} duration={500}>
          <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
            View Work
            <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3 ' />
            </span>
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;